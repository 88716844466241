<template>
    <span style="min-width: 300px">
        <a href="#"
           @click.prevent="$root.$children[0].openModal('customer-modal', {customerId:row.item.id}, refreshTable)">
            {{ row.item.company }}
        </a>
    </span>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>